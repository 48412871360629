
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import ReportService from "../../../api/report-service";

export default Vue.extend({
    name: "ReassignReviewerModal",
    props: {
        reportId: { type: String, required: true },
        report: { type: Object, required: true },
        currentReviewer: { type: Object, required: true },
        usage: { type: String, required: false, default: "frc" },
    },
    data() {
        return {
            isSaving: false,
            form: { reviewerId: [] as any[] },
        };
    },
    computed: {
        ...mapState("users", {
            reviewers: (state: any) => state.levels?.find((level: any) => level.category === "ReportReviewer")?.users,
        }),
    },
    mounted() {
        this.setupForm();
    },
    methods: {
        ...mapActions("reports", ["fetchReportList"]),
        cancel() {
            this.$bvModal.hide(`modal-reassign-reviewer-${this.reportId}`);
        },
        async save() {
            this.isSaving = true;

            const body = {
                userId: this.form.reviewerId[0]?.id || "",
            };

            try {
                const res = await ReportService.reassignReviewer({
                    id: this.report.id as string,
                    body,
                });

                this.$bvToast.toast(res.data.message, { title: "Success!", variant: "success", solid: true });

                await this.fetchReportList();
                this.cancel();
            } catch (error) {
                this.isSaving = false;
            } finally {
                this.isSaving = false;
            }
        },
        async reassignSectorialReviewer() {
            this.isSaving = true;
            const { id: reportId, regulator: regulatorId } = this.$route.params;
            const body = { userId: this.form.reviewerId[0]?.id || "" };
            try {
                const response = await ReportService.reassignSectorialReviewer({ reportId, regulatorId }, body);
                this.$bvToast.toast(response.data.message, { title: "Success!", variant: "success", solid: true });
                this.isSaving = false;
                this.cancel();
                this.$router.replace({ name: "reports.view", params: { id: reportId } });
            } catch (error) {
                this.isSaving = false;
            }
        },
        setupForm() {
            if (this.usage === "others" && this.currentReviewer.companyReviewerId) {
                const reviewer = {
                    id: this?.currentReviewer?.companyReviewerId ?? "",
                    fullName: this?.currentReviewer?.companyReviewerDetails?.fullname ?? "",
                    email: this.currentReviewer.companyReviewerDetails?.email ?? "",
                    position: this.currentReviewer.companyReviewerDetails?.position ?? "",
                };
                this.form.reviewerId = [reviewer];
            } else if (this.usage === "frc") {
                this.form.reviewerId = [this.currentReviewer];
            }
        },
    },
    watch: {
        currentReviewer() {
            this.setupForm();
        },
    },
    validations: {
        form: {
            reviewerId: {
                required,
            },
        },
    },
});
