var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container d-flex flex-column",
    attrs: {
      "id": `reassign-reviewer-${_vm.reportId}`,
      "title": 'Reassign Reviewer',
      "description": 'Provide a different reviewer for the reporting process',
      "custom-content-class": "overflow-scroll selectable-modal__content d-flex flex-column"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-selectable-file-input', {
    attrs: {
      "label": 'Select Reviewer',
      "selected-label": 'Selected Reviewer',
      "listTitle": "Reviewers",
      "noCreate": "",
      "values": _vm.reviewers,
      "loadingValues": false,
      "type": "single",
      "required": true,
      "defaultValue": _vm.form.reviewerId
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.reviewerId.$touch();
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.$v.form.reviewerId.$error ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Select at least one reviewer")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.reviewerId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reviewerId", $$v);
      },
      expression: "form.reviewerId"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mx-0 mt-auto"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('BaButton', {
    staticClass: "text-black",
    attrs: {
      "text": "Cancel",
      "variant": "gray-500"
    },
    on: {
      "click": _vm.cancel
    }
  }), _vm.usage === 'frc' ? _c('BaButton', {
    staticClass: "ml-4 text-black",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSaving,
      "loading": _vm.isSaving,
      "text": "Reassign Reviewer",
      "variant": "warning"
    },
    on: {
      "click": _vm.save
    }
  }) : _c('BaButton', {
    staticClass: "ml-4 text-black",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isSaving,
      "loading": _vm.isSaving,
      "text": "Reassign Reviewer",
      "variant": "warning"
    },
    on: {
      "click": _vm.reassignSectorialReviewer
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }